<!-- 地图标绘 -->
<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 500px;
      "
    >
      <div
        style="border-radius: 10px; width: 100%; height: 100%"
        id="container"
      ></div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <span>楼栋经纬度:</span>
      <a-input
        v-model="Lng"
        style="padding: 6px 11px; font-size: 16px; width: 20%; margin-left: 5px"
      />

      <div style="width: 4%; display: flex; justify-content: center">-</div>

      <a-input
        v-model="Lat"
        style="padding: 6px 11px; font-size: 16px; width: 20%"
      />
      <ax-button
        type="#e6a23c"
        @click="location"
        style="
          width: 10%;
          margin-left: 5px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        定位
      </ax-button>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 20px" @click="close"> 取消 </a-button>
    </div>
  </div>
</template>
  <script>
import api from "../api";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  data() {
    return {
      api,
      map: null,
      Lng: "",
      Lat: "",
      locations: null,
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.initAMap();
  },
  methods: {
    initAMap() {
      const self = this; // 存储当前的this
      AMapLoader.load({
        key: "6636defcef28a2bf1f1043f154835db6", // 申请好的Web端开发者Key，首次调用 load 时必填
        // version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        version: "2.0",
        plugins: ["AMap.MouseTool"],
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          self.map = new AMap.Map("container", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            zoom: 15, // 初始化地图级别
            terrain: true, //开启地形图
            center: [117.303216, 31.803165], // 初始化地图中心点位置
          });
          self.map.on("click", function (e) {
            self.map.clearMap();
            self.Lng = e.lnglat.getLng();
            self.Lat = e.lnglat.getLat();
            //创建一个 点位实例：
            const marker = new AMap.Marker({
              position: new AMap.LngLat(self.Lng, self.Lat), //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: "场所",
            });
            //将创建的点标记添加到已有的地图实例：
            self.map.add(marker);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //提交
    async onSubmit() {
      // 打印定位的经度和纬度值
      // console.log("经度:", this.Lng, "纬度:", this.Lat);
      // 触发自定义事件，并传递经度和纬度值
      this.$emit("locationUpdated", { lng: this.Lng, lat: this.Lat });
      // 提交成功后关闭弹窗
      this.$emit("close");
    },
    //关闭弹窗
    close() {
      this.$emit("close");
    },
    location() {
      if (!this.Lng || !this.Lat) {
        this.$message.error("请输经纬度");
        return;
      }
      this.map.clearMap();
      this.map.setCenter([this.Lng, this.Lat]); //map为地图实例,lng为经度,lat为纬度
      const marker = new AMap.Marker({
        position: new AMap.LngLat(this.Lng, this.Lat),
        title: "场所",
      });
      //将创建的点标记添加到已有的地图实例：
      this.map.add(marker);
    },
    getlocation(e) {
      this.locations = e;
      this.Lat = e.longitude;
      this.Lng = e.latitude;
      setTimeout(() => {
        if (e && this.map) {
          this.map.setCenter([e.latitude, e.longitude]);
          const marker = new AMap.Marker({
            position: new AMap.LngLat(e.latitude, e.longitude),
          });
          //将创建的点标记添加到已有的地图实例：
          this.map.add(marker);
        }
      }, 1000);
    },
  },
};
</script>
      
  <style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
</style>
      